@import "src/globals";

.main-dashboard-layout {
  background-color: $colorSecondaryLight;
  padding: 20px;
  display: flex;
  width: 100vw;
  min-height: 100vh;
  max-width: 100vw;
  .dashboard-header {
    width: auto;
    max-width: 80vw;
  }

  .dashboard-body {
    flex: 1;
    overflow: hidden;
    padding-left: 70px;
    @media (max-width: 768px) {
      padding-left: 0px;
    }
    .header-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 40px;
      padding-right: 25px;
      @media (max-width: 768px) {
        padding: 0px;
        align-items: flex-start;
        margin-bottom: 30px;
        margin-left: 60px;
        margin-top: 10px;
      }

      .dashboard-title {
        font-family: $fontFamilyPrimary;
        font-size: 24px;
        font-weight: 600;
        color: $colorPrimaryDark;
        span {
          font-size: 14px;
          font-weight: 400;
        }
        @media (max-width: 768px) {
          font-size: 16px;
          line-height: 1.1;
          margin-right: auto;
          margin-left: 10px;
          max-width: 100px;
        }
      }
      .aura-logo {
        width: 150px;
        margin-left: 20px;
        @media (max-width: 768px) {
          width: 100px;
          margin: 0px;
          display: none;
        }
      }
    }
    .dashboard-content {
      padding-left: 40px;
      padding-right: 25px;
      padding-top: 30px;
      @media (max-width: 768px) {
        padding: 0px;
        margin-top: 60px;
      }
    }
  }
}
