@import "src/globals";

.stats-number-box {
  border-radius: 8px;
  border: 1px solid $colorSecondaryDark;
  position: relative;
  padding: 10px 18px;
  padding-top: 22px;
  font-family: $fontFamilyPrimary;
  width: max-content;
  .subTitle-container {
    position: absolute;
    left: 15px;
    top: 0px;
    transform: translateY(-50%);
    background-color: $colorSecondaryDark;
    padding: 3px 16px;
    border-radius: 50px;
    p {
      font-size: 12px;
    }
  }
  .title {
    font-size: 20px;
    font-weight: 600;
    padding: 0px;
    margin: 0px;
    line-height: 1.2;
  }
  .numbers-container {
    display: flex;
    font-family: $fontFamilySecondary;
    align-items: flex-end;
    justify-content: space-between;
    .compared-container {
      display: flex;
      margin-right: 30px;
      align-items: center;
      p {
        font-size: 10px;
        margin-left: 2px;
        text-wrap: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .main-number-container {
      font-size: 24px;
      font-weight: 700;
      span {
        font-size: 12px;
        margin-left: 3px;
        font-weight: 400;
      }
    }
  }
}
