.home {
  position: relative;
  background-color: var(--primary-color);
  overflow: hidden;
  height: 100vh;
}
.home .left-vector {
  width: 20%;
  height: 180px;
}
.home .left-vector img {
  width: 100%;
}

.home .right-vector {
  height: auto;
  width: 20%;
  position: absolute;
  right: 0;
  bottom: 0;
}
.home .right-vector img {
  transform: rotate(180deg);
  width: 100%;
}

.aura-container {
  color: var(--secondary-color);
  font-size: 30px;
  font-family: AnyaTamy;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.home .aura-container h1 {
  font-family: AffiliateMastery;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 10px;
}
.home .aura-container h4 {
  font-family: Cairo;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.aura-container img {
  width: 35%;
  margin-bottom: 10px;
  margin-top: 40px;
}
.call-to-action-btn {
  margin-top: 20px;
  background-color: var(--secondary-color);
  color: var(--primary-color);
  border: 0cap;
  border-radius: 10px;
  width: 193px;
  height: 41px;
  font-size: 16px;
  margin: 2px;
  cursor: pointer;
  transition: 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.call-to-action-btn:hover {
  color: var(--secondary-color);
  background-color: var(--primary-color-dk);
}
.home .comming-soon {
  width: 20%;
  height: 180px;
  position: relative;
}
.home .comming-soon img {
  width: 100%;
}
@media only screen and (max-width: 600px) {
  .home .right-vector {
    width: 30%;
  }
  .home .right-vector img {
    transform: rotate(180deg);
    width: 100%;
  }

  .aura-container img {
    width: 50%;
    margin-bottom: 10px;
    margin-top: 2%;
  }
  .home .aura-container h1 {
    font-size: 14px;
    margin-top: 0px;
  }
  .home .aura-container h3 {
    font-size: 13px;
  }
  .home .aura-container h4 {
    font-size: 9px;
  }
  .call-to-action-btn {
    margin-top: 30px;
    border: 0cap;
    border-radius: 5px;
    width: 154px;
    height: 41px;
    font-size: 13px;
  }

  .home .comming-soon {
    width: 80%;
    margin: auto;
    height: auto;
    margin-top: 50px;
  }
  .home .comming-soon img {
    width: 100%;
  }
}
@media only screen and (min-width: 1500px) and (max-width: 1540px) {
  .aura-container img {
    width: 25%;
    height: 110px;
    margin-top: 30px;
  }
  .home h1 {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .home h3 {
    font-size: 20px;
  }
  .call-to-action-btn {
    margin-top: 20px;
    border: 0cap;
    border-radius: 5px;
    width: 194px;
    height: 41px;
    font-size: 14px;
  }
  .home .right-vector img {
    position: absolute;
    right: 0;
    bottom: 0;
    transform: rotate(180deg);
    width: 199px;
    height: 179px;
  }

  .home .comming-soon {
    width: 20%;
    height: auto;
    margin-top: 20px;
  }
  .home .comming-soon img {
    width: 100%;
  }
}

@media only screen and (min-width: 1541px) {
  .home .comming-soon {
    width: 20%;
    height: auto;
    margin-top: 40px;
  }
  .home .comming-soon img {
    width: 100%;
  }
}
