@import "src/globals";

.prompt-container {
  .title {
    margin-bottom: 5px;
  }
  .actions-container {
    display: flex;
    justify-content: space-between;
    padding: 0px 40px;
  }
  p {
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    margin-bottom: 20px;
    padding: 0px 10px;
  }
}
