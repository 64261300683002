@import "src/globals";

.select-container {
  position: relative;
  cursor: pointer;
  select {
    padding: 8px;
    padding-left: 16px;
    background-color: var(--primary-color);
    border: 0px;
    outline: 0px;
    border-radius: 4px;
    color: white;
    font-family: $fontFamilyPrimary;
    font-size: 16px;
    font-weight: light;
    width: 100%;
  }

  .arrow-down {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}
