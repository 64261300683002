.stats-boxes-container {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  & > div {
    flex: 1;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
    align-items: flex-end;
  }
}
