.ticket-details {
  background-color: var(--primary-color);
  padding: 30px;
  max-height: auto;
  min-height: 100vh;
}
.ticket-details h1 {
  color: white;
  font-family: AffiliateMastery;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.any-help {
  color: white;
  text-align: center;
  font-family: AoboshiOne;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 5px;
  margin-bottom: 25px;
}
.ticket-details .ticket-container {
  width: 50%;
  height: auto;
  border-radius: 10px;
  background: var(--secondary-color);
  padding: 40px;
}
.ticket-details .ticket-container h5 {
  color: var(--primary-color-dk);
  font-family: AoboshiOne;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.ticket-details .ticket-container h2 {
  color: var(--primary-color);
  font-family: AoboshiOne;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ticket-details .ticket-container h4 {
  color: var(--primary-color);
  font-family: AoboshiOne;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: max-content;
  margin: auto;
  font-family: Cairo;
  border-radius: 6px;
}
.circle-container {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--primary-color);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.circle-container h3 {
  color: var(--secondary-color);
  font-family: AoboshiOne;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 5px;
}

.ticket-container h6 {
  color: var(--primary-color);
  font-family: AoboshiOne;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.qr-code p {
  color: var(--secondary-color-dk1);
  font-family: AoboshiOne;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.qr-code img {
  width: 20%;
}
.post-img {
  width: 50%;
  margin: auto;
}
.post-img img {
  width: 100%;
}
@media (max-width: 767px) {
  .ticket-details h1 {
    font-size: 16px;
    margin-top: 70px;
  }
  .any-help {
    font-size: 12px;
  }
  .ticket-details .ticket-container {
    width: 100%;
    padding: 30px;
  }
  .ticket-details .ticket-container h5 {
    color: var(--primary-color-dk);
    font-size: 14px;
  }
  .ticket-details .ticket-container h2 {
    font-size: 12px;
  }
  .circle-container {
    width: 30px;
    height: 30px;
  }
  .circle-container h3 {
    font-size: 16px;
  }

  .ticket-container h6 {
    font-size: 12px;
  }

  .qr-code p {
    font-size: 12px;
  }
  .qr-code img {
    width: 30%;
  }
  .post-img {
    width: 80%;
  }
}
