@import "../../../../globals.scss";

.white {
  color: $colorWhite;
}

.colorPrimaryLight {
  color: $colorPrimaryLight;
}
.main-bg-light {
  background-color: $colorPrimaryLight;
}
.main-bg-dark {
  background-color: $colorPrimaryDark;
}
.second-bg-dark {
  background-color: $colorSecondaryDark;
}
.colorSecondaryDarker {
  color: $colorSecondaryDarker;
}
.reception-container {
  display: flex;
  gap: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 25px;
    margin-top: 10px;
  }

  .side-reception {
    .new-customer-btn {
      padding: 30px 20px;
      border-radius: 4px;
      border: 1px solid rgba(63, 70, 49, 0.25);
      background: $colorPrimaryLight;
      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
      color: $colorWhite;
      font-size: 18px;
      font-weight: 500;
      font-family: $fontFamilyPrimary;
      transition: 0.2s ease;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      &:hover {
        background-color: $colorPrimaryDark;
      }
      @media (max-width: 768px) {
        padding: 10px 15px;
        font-size: 14px;
        width: 100%;
        flex: 1;
      }
    }
  }

  .table-container {
    flex: 1;
    overflow: hidden;
    // margin-right: 40px;
    // width: 95%;
    .tooltip-container {
      .fees-cell {
        background-color: $colorSecondaryDarker;
        color: white;
        padding: 4px 40px;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80%;
        font-size: 14px;
      }
    }

    .source-cell {
      &-returningCustomer {
        @include tag-cell;
        background-color: #fda40380;
      }
      &-passingBy {
        @include tag-cell;
        background-color: #11423280;
      }
      &-friend {
        @include tag-cell;
        background-color: #2c786580;
      }
      &-instagram {
        @include tag-cell;
        background-color: #d3756b80;
      }
      &-tiktok {
        @include tag-cell;
        background-color: #fa707080;
      }
      &-hotdesk {
        @include tag-cell;
        background-color: #fcdc2a80;
      }
      &-googleMaps {
        @include tag-cell;
        background-color: #fa707080;
      }
      &-package {
        @include tag-cell;
        background-color: #ff8f8f80;
      }
    }
  }
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}

.reterning-coustomuer-icons {
  font-size: 25px;
}
@mixin top-buttons {
  width: 190px;
  height: 40px;
  border: 0;
  color: $colorWhite;
  position: relative;

  p {
    font-size: 15px;
    color: $colorPrimaryLight;
  }
}

.customer-name-phone {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.active-customer-payment-tap {
  padding: 1px 15px;
  border-radius: 20px;
  background-color: $colorSecondaryDarker;
  outline: 0;
  border: 0;
  margin-right: 10px;
  color: $colorWhite;
  font-family: AoboshiOne;
}

.customer-payment-tap {
  padding: 1px 15px;
  border-radius: 20px;
  background-color: $colorSecondaryLight;
  outline: 0;
  border: 0;
  margin-right: 10px;
  font-family: AoboshiOne;

  color: $colorWhite;
}
