@import "src/globals";

.input-container {
  position: relative;
  margin-bottom: 18px;
  p {
    text-align: left;
    position: absolute;
    font-size: 8px;
    color: $colorSecondaryDarker;
    top: -15px;
    left: 0px;
  }
  .input-component {
    width: 100%;
    height: 40px;
    display: block;
    border-radius: 4px;
    background: $colorSecondaryLight;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
    border: 0;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 0px 20px;
    outline: 0;
    font-weight: 600;
    font-size: 14px;
    font-family: $fontFamilyPrimary;
    &.long-text {
      height: 80px;
      padding-top: 10px;
      padding-left: 18px;
    }
  }
  .trailing-label {
    position: absolute;
    top: 0px;
    right: 0;
    height: 40px;
    padding: 10px 20px;
    background-color: $colorSecondaryDarker;
    color: white;
    border-radius: 0px 4px 4px 0px;
    font-weight: 700;
  }
}
