@import "src/globals";

.event-box {
  border-radius: 8px;
  overflow: hidden;
  background-color: #ddc7ba33;
  height: 250px;
  margin-bottom: 25px;
  display: flex;
  border: 1px solid #ddc7ba80;
  box-shadow: 0px 2px 10px #3f46310d;
  font-family: $fontFamilyPrimary;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    height: auto;
    position: relative;
  }
  .content {
    padding: 15px 25px;
    display: flex;
    flex-direction: column;
    flex: 1;
    @media (max-width: 768px) {
      padding: 10px 15px;
    }
    .title-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h1 {
        font-size: 22px;
        padding-bottom: 0px;
        line-height: 3;
      }
      span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 35px;
        height: 35px;
        background-color: $colorPrimaryDark;
        color: white;
        border-radius: 50px;
        font-size: 12px;
      }
      @media (max-width: 768px) {
        h1 {
          font-size: 18px;
          line-height: 1.6;
          margin-bottom: 10px;
        }
        span {
          width: 25px;
          height: 25px;
          font-size: 10px;
          position: absolute;
          top: 10px;
          right: 10px;
        }
      }
    }
    .description {
      margin-bottom: 10px;
      overflow: scroll;
      text-overflow: ellipsis;
      max-height: 80px;
      line-height: 20px;
      -ms-overflow-style: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .date {
      font-weight: 700;
      color: $colorPrimaryLight;
      @media (max-width: 768px) {
        text-align: center;
      }
    }
    .footer {
      display: flex;
      justify-content: space-between;
      margin-top: auto;
      @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        gap: 20px;
        margin-top: 20px;
        margin-bottom: 10px;
      }
      div:nth-child(1) {
        display: flex;
        align-items: center;
        gap: 15px;
        h5 {
          font-weight: 600;
        }
        span {
          background-color: $colorSecondaryDark;
          border-radius: 30px;
          padding: 2px 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          &:nth-child(3) {
            background-color: $colorPrimaryDark;
            color: white;
            font-weight: 600;
            margin-left: 20px;
          }
        }
        .price {
          @media (max-width: 768px) {
            position: absolute;
            top: 10px;
            left: 0px;
          }
        }
      }
      div:nth-child(2) {
        display: flex;
        gap: 30px;
      }
    }
  }
  .thumbnail-container {
    width: 250px;
    height: 250px;
    min-width: 250px;
    min-height: 250px;
    margin-left: auto;
    img {
      height: 100%;
      object-fit: cover;
    }
    @media (max-width: 768px) {
      width: 100%;
      min-width: 100%;
      min-height: 200px;
      height: auto;
      img {
        height: auto;
      }
    }
  }
}
