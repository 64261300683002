@import "src/globals";
.success {
  background-color: $colorSecondaryLight;
  width: fit-content;
  margin: auto;
  padding: 50px;
  border-radius: 10px;

  .check-icon {
    color: $colorSecondaryLight;
    font-size: 40px;
    width: 65px;
    height: 65px;
    border-radius: 50%;
    background-color: $colorPrimaryLight;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  h2 {
    color: #6d7254;
    text-align: center;
    font-family: AffiliateMastery;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 15px;
  }
  p {
    color: #aa7258;
    text-align: center;
    font-family: AoboshiOne;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
