@import "src/globals";

.payment-method-input {
  .input-container {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 35px;
    margin-bottom: 15px;

    .wide-input {
      width: 100%;
      background-color: $colorSecondaryLight;
      color: #6d7254;
      outline: 0;
      border: 0;
      height: 100%;
      margin: 0;
      border-radius: 0;
      padding-left: 12px;
    }

    .left-button {
      width: 40%;
      background-color: $colorPrimaryLight;
      color: $colorWhite;
      outline: 0;
      border-radius: 4px 0 0 4px;
      height: 100%;
      cursor: auto;
    }

    .right-button {
      width: 20%;
      background-color: $colorSecondaryDarker;
      outline: 0;
      border-radius: 0 4px 4px 0px;
      border: 0;
      color: white;
      height: 100%;
    }
  }
}
