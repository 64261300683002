@import "src/globals";

.sale-box-container {
  background-color: #ddc7ba80;
  border-radius: 8px;
  border: 1px solid rgba($color: $colorPrimaryLight, $alpha: 0.5);
  padding: 12px 15px;
  display: flex;
  justify-content: space-between;
  font-family: $fontFamilyPrimary;
  .left-wrapper {
    flex: 1;
    padding-right: 25px;
    .header {
      display: flex;
      align-items: center;
      flex: 1;
      .sale-id {
        background-color: $colorPrimaryDark;
        color: $colorWhite;
        border-radius: 100px;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 10px;
      }
      .sale-date {
        margin-left: 15px;
        color: $colorPrimaryDark;
        font-size: 12px;
      }
    }
    .items {
      margin-top: 12px;
      .item-row {
        margin-bottom: 4px;
        display: flex;
        align-items: center;
        span {
          color: $colorPrimaryDark;
          &:nth-child(1) {
            font-size: 12px;
            margin-right: 8px;
          }
          &:nth-child(2) {
            font-size: 16px;
          }
          &:nth-last-child(1) {
            margin-left: auto;
          }
        }
      }
    }
    .line-separator {
      width: 100%;
      height: 1px;
      border-radius: 10px;
      background-color: $colorSecondaryDark;
      margin: 8px 0px;
    }
    .footer {
      color: $colorPrimaryDark;
      display: flex;
      align-items: center;
      div {
        margin-left: auto;
        display: flex;
        align-items: center;
        .payment-method {
          background-color: $colorSecondaryDark;
          font-size: 10px;
          padding: 2px 18px;
          border-radius: 50px;
          margin-right: 12px;
          text-transform: capitalize;
        }
        .total {
          font-size: 18px;
          font-weight: 700;
        }
      }
    }
  }
  .right-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 120px;
    .customer-info {
      background-color: $colorPrimaryLight;
      color: $colorWhite;
      border-radius: 4px;
      padding: 4px 10px;
      div {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        p {
          font-weight: 700;
        }
        span {
          font-size: 12px;
        }
      }
    }
    .sale-status-label {
      background-color: $colorPrimaryDark;
      font-size: 12px;
      padding: 2px 18px;
      border-radius: 50px;
      text-transform: capitalize;
      color: white;
      text-align: center;
    }
  }
}
