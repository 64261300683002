@import "src/globals";

.model-header {
  border: 0;
  padding: 15px 15px 0px 15px;
}
.model-footer {
  text-align: center;
}
.modal-body {
  padding: 0px 20px 20px;
  text-align: center;
  .title {
    color: $colorPrimaryDark;
    font-family: $fontFamilyPrimary;
    font-size: 24px;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    flex: 1;
    margin-bottom: 15px;
  }
}
.model {
  .input {
    width: 100%;
    height: 45px;
    border-radius: 4px;
    background: $colorSecondaryLight;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
    border: 0;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 0px 20px;
    outline: 0;
  }
  .selector {
    margin: auto;
    width: 350px;
    display: block;
    border-radius: 4px;
    background: $colorPrimaryLight;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
    border: 0;
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 20px;
    outline: 0;
    color: $colorWhite;
  }
  .option {
    border-radius: 0;
    color: $colorWhite;
  }
  .option:hover {
    border-radius: 0;
    color: $colorPrimaryLight;
    background-color: $colorWhite;
  }
  ::placeholder {
    color: rgba(109, 114, 84, 0.5);
    margin-left: 20px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-family: AoboshiOne;
  }
  .new-coustomer-button {
    border: 0;
    padding: 6px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    border: 1px solid rgba(63, 70, 49, 0.25);
    background: $colorSecondaryDarker;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
    color: $colorWhite;
    margin-top: 60px;
    h6 {
      text-align: center;
      font-family: AoboshiOne;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .search-data {
    border-top: 1px solid $colorSecondaryLight;
    border-bottom: 1px solid $colorSecondaryLight;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  }
  .select-button {
    padding: 0px 37px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    border: 1px solid rgba(63, 70, 49, 0.25);
    background: $colorPrimaryLight;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
    color: #fff;
  }
}
