@import "src/globals";

.search-results-table {
  width: 100%;
  border-collapse: collapse;
  font-family: $fontFamilyPrimary;
  margin-bottom: 30px;
  margin-top: 10px;
  tr {
    border-top: 1px solid $colorSecondaryDark;
    border-bottom: 1px solid $colorSecondaryDark;
  }
  td {
    padding: 7px 0px;
    color: $colorPrimaryDark;
    text-align: left;
    &:nth-child(1) {
      font-size: 12px;
      padding-right: 10px;
    }
    &:nth-last-child(1) {
      margin-left: auto;
      // display: flex;
      justify-content: flex-end;
      align-items: center;
      text-align: right;
    }
    &:not(:last-child):not(:first-child) {
      font-weight: 600;
      font-size: 14px;
      padding-right: 5px;
    }
    .phone-cell {
      display: block;
      background-color: $colorSecondaryDarker;
      color: $colorWhite;
      padding: 3px 8px;
      width: max-content;
      height: max-content;
      border-radius: 50px;
      span {
        font-size: 8px;
        margin-right: 2px;
      }
    }

    .friends-dropdown {
      background-color: "#DDC7BA";
      border: 0 solid #e6d8c2;
      padding: 4px;
      border-radius: 5px;
      width: 45px;
      outline: none;
      appearance: auto;
      margin-right: 10px;
    }
  }
}
