@import "src/globals";

.customers-page {
  font-family: $fontFamilyPrimary;
  display: flex;
  & > div {
    flex: 1;
    &:nth-child(2) {
      flex: 6;
    }
  }
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }
  .title-label {
    color: $colorPrimaryDark;
    padding: 2px 30px;
    font-size: 18px;
    background-color: $colorSecondaryDark;
    width: max-content;
    border-radius: 30px;
    margin-bottom: 15px;
  }
  .whatsapp-details-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .connection-status-container {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      p {
        font-weight: 700;
        margin-top: 0px;
        margin-inline-start: 7px;
      }
    }
    .qr-code-container {
      overflow: hidden;
      width: 170px;
      height: 170px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
