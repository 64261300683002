@tailwind base;
@tailwind components;
@tailwind utilities;

/* Badge styles - used in tables */
.badge-base {
  @apply flex h-7 cursor-pointer select-none items-center justify-center rounded-full px-5 text-sm text-white;
}

.badge-1 {
  @apply badge-base bg-[#fda40380];
}

.badge-2 {
  @apply badge-base bg-[#11423280];
}

.badge-3 {
  @apply badge-base bg-[#2c786580];
}

.badge-4 {
  @apply badge-base bg-[#d3756b80];
}

.badge-5 {
  @apply badge-base bg-[#fa707080];
}

.badge-6 {
  @apply badge-base bg-[#fcdc2a80];
}

.badge-7 {
  @apply badge-base bg-[#fa707080];
}

.badge-8 {
  @apply badge-base bg-[#ff8f8f80];
}

.badge-9 {
  @apply badge-base bg-[#9b59b680];
}

.badge-10 {
  @apply badge-base bg-[#3498db80];
}

.badge-11 {
  @apply badge-base bg-[#e67e2280];
}

.badge-12 {
  @apply badge-base bg-[#27ae6080];
}

/* Label styles */
.section-label {
  @apply text-primary-dark bg-secondary-dark !mb-4 w-max rounded-full px-7 py-0.5 text-lg font-medium;
}

.amount-label {
  @apply bg-secondary-darker flex cursor-pointer items-center justify-center rounded-full px-10 py-1 text-sm text-white;
}
