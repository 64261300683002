@import "src/globals";

.create-event-modal {
  .upload-image-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    background-color: $colorSecondaryLight;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 50px;
    color: $colorSecondaryDarker;
    font-family: $fontFamilySecondary;
    span {
      margin-top: 7px;
    }

  }
  .create-event-button {
    margin-top: 30px;
    justify-self: center;
    scale: 1.2;
  }
}
