@import "src/globals";

.PaymentPage {
  background-color: var(--primary-color);
  position: relative;
  min-height: 100vh;
  padding-top: 48px;
  padding-bottom: 48px;
}
.left-vector-with-aura-payment {
  position: absolute;
  top: 0;
  left: 0;
  width: 13%;
}
.left-vector-with-aura-payment img {
  width: 100%;
}
.PaymentPage .welcome h1 {
  color: white;
  font-family: AffiliateMastery;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.PaymentPage .welcome p {
  color: var(--secondary-color-dk1);
  text-align: center;
  font-family: AoboshiOne;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
}
.any-help {
  color: white;
  text-align: center;
  font-family: AoboshiOne;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 5px;
  margin-bottom: 25px;
}
.select-payment-method-img {
  width: 25%;
  margin: auto;
}
.select-payment-method-img img {
  width: 100%;
}
.event-desc {
  width: 80%;
  height: auto;
  border-radius: 10px;
  background: var(--secondary-color);
}
.event-desc h5 {
  color: var(--primary-color-dk);
  font-family: AoboshiOne;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.total-word {
  color: #3b2f1a;
}

.btn {
  border-radius: 10px;
  background: var(--secondary-color-dk1);
  color: var(--primary-color-dk);
  font-family: Aclonica;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.countdown-container {
  background-color: #6d7254;
  width: fit-content;
  margin: 10px auto;
  border-radius: 14px;
  padding: 10px 20px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.item-holder {
  margin: auto;
  width: 45px;
  height: 45px;
  background-color: #efe6dc;
  font-family: cairo;
  padding: 3px;
  color: black;
  border-radius: 50%;
  margin: 0 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.PaymentPage {
  background-color: var(--primary-color);
  position: relative;
}
.left-vector-with-aura-payment {
  position: absolute;
  top: 0;
  left: 0;
}
.PaymentPage .welcome h1 {
  color: #fff;
  font-family: AffiliateMastery;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.PaymentPage .welcome p {
  color: var(--secondary-color-dk1);
  text-align: center;
  font-family: AoboshiOne;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.event-desc {
  width: 80%;
  height: auto;
  border-radius: 10px;
  background: var(--secondary-color);
}
.event-desc h5 {
  color: var(--primary-color-dk);
  font-family: AoboshiOne;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.event-desc .avalible-seats {
  color: var(--primary-color-dk);
  font-family: AoboshiOne;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 10px;
  margin-right: 5px;
}

.separator-line {
  color: $colorPrimaryDark;
  border: 1px dashed $colorPrimaryDark;
}

.about-the-event {
  display: flex;
  gap: 50px;
  margin: 30px 0px;
  .image-timer-wrapper {
    flex: 1;
  }
  .img-event {
    margin: auto;
    width: 100%;
    margin: 20px auto;
    border-radius: 8px;
    overflow: hidden;
    img {
      width: 100%;
    }
  }
  p {
    flex: 2;
    color: var(--primary-color);
    font-family: Cairo;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
  }
}

.event-tickets-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
  .selector-container {
    display: flex;
    align-items: center;
    gap: 15px;
    height: max-content;
  }
  .total-price {
    display: flex;
    align-items: center;
    h5 {
      font-size: 20px;
    }
  }
  h6 {
    margin: 0 !important;
  }
}

.btn {
  border-radius: 10px;
  background: var(--secondary-color-dk1);
  color: var(--primary-color-dk);
  font-family: Aclonica;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.img-event .btn:hover {
  background: var(--secondary-color-dk1);
}
.event-ticket-color {
  color: var(--primary-color);
  font-family: AoboshiOne;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.bay-btn {
  border-radius: 6px;
  background: var(--primary-color-dk);
  color: white;
  width: 20%;
}
.bay-btn:hover {
  background: var(--primary-color-dk);
  color: white;
}

.payment-methods-container {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 50px auto;
  gap: 20px;
  .payment-method-radio {
    display: flex;
    .radioLabel {
      display: flex;
      align-items: center;
      color: var(--primary-color);
      font-family: AoboshiOne;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      input[type="radio"] {
        margin-right: 8px;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        width: 16px;
        height: 16px;
        border: 2px solid var(--primary-color-dk); /* Set a border for the transparent circle */
        border-radius: 50%; /* Makes the border a circle */
        outline: none;

        &[type="radio"]:checked {
          background-color: var(--primary-color);
        }
      }
    }
  }
}

.wide-dash-line {
  margin: auto;
  width: 60%; /* Adjust the width as needed */
  border-bottom: 2px dashed #6d7254; /* Adjust the thickness and color */
}

.seat-count {
  width: 130px;
  height: 49.723px;
  position: relative;
}
.seat-count img {
  width: 100%;
  height: 100%;
}
.avalible-seats-counter {
  position: absolute;
  left: 25px;
  top: 16px;
  color: #efe6dc;
  font-family: Aclonica !important;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.person-icon {
  position: absolute;
  left: 25px;
  top: 16px;
  color: rgb(255, 255, 255);
}
@media (max-width: 767px) {
  .seat-count {
    width: 90.796px;
    height: 39.723px;
  }
  .avalible-seats-counter {
    position: absolute;
    left: 17px;
    top: 12px;
    color: #efe6dc;

    font-family: Aboshi !important;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .about-the-event {
    flex-direction: column;
    margin-top: 0px;
    gap: 20px;
    .image-timer-wrapper {
      flex: unset;
    }
    .img-event {
      img {
        height: 100%;
        object-fit: contain;
      }
    }
    p {
      flex: unset;
      font-size: 14px !important;
    }
  }
  .event-tickets-container {
    justify-content: center;
  }
}

/* Regular styles */

@media (max-width: 767px) {
  .PaymentPage {
    padding-left: 15px;
    padding-right: 15px;
  }
  .left-vector-with-aura-payment {
    width: 25%;
  }
  .PaymentPage .welcome h1 {
    margin-top: 50px;
    font-size: 20px;
  }
  .PaymentPage .welcome p {
    color: var(--secondary-color-dk1);
    font-size: 10px;
  }
  .any-help {
    font-size: 12px;

    margin-top: 5px;
    margin-bottom: 25px;
  }

  .select-payment-method-img {
    width: 200px;
    margin: auto;
  }
  .event-desc {
    width: 100%;
  }
  .big-title {
    display: flex;
    flex-direction: column-reverse;
  }
  .avalible-seats-icon {
    margin-bottom: 20px;
  }
  .dropdown-price {
    margin-top: 20px;
  }

  .event-ticket-word {
    font-size: 13px;
  }
  .img-event {
    width: 100%;
  }
  .radioLabel {
    font-size: 14px;
    margin-top: 5px;
  }

  .radioLabel input[type="radio"] {
    margin-right: 12px; /* Adjust the margin as needed */
    width: 12px;
    height: 12px;
  }
  .bay-btn {
    width: 40%;
  }
  .wide-dash-line {
    margin: auto;
    width: 90%; /* Adjust the width as needed */
    border-bottom: 2px dashed var(--primary-color); /* Adjust the thickness and color */
  }
  .about-the-event p {
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding-left: 0;
    padding-right: 0;
  }
  .about-the-event span {
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .event-desc .avalible-seats {
    font-size: 16px;
    margin-right: 10px;
    font-family: AoboshiOne;
  }
  .total-word {
    text-align: start;
  }
  .ticket-price-mobile {
    margin-left: 55px;
  }
  .margin-top-mobile {
    margin-top: 150px;
  }
  .item-holder {
    width: 25px;
    height: 25px;
    font-size: 10px;
  }
}
