@import "src/globals";

.nav-bar-wrapper {
  position: fixed;
  top: 20px;
  left: 20px;
  bottom: 20px;
  display: flex;
  flex-direction: column;
  z-index: 100;
  height: 100vh;
  @media (max-width: 768px) {
    left: -80px;
    top: 10px;
    &:hover {
      transition: 0.2s ease;
      left: 10px;
    }
  }
  .nav-bar-container {
    background-color: $colorPrimaryLight;
    border-radius: 6px;
    float: left;
    border: 2px solid rgba(63, 70, 49, 0.25);
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
    padding-top: 15px;
    position: relative;
    .logo-container {
      padding: 0px 15px;
      background-color: $colorPrimaryDark;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      margin: auto;
      margin-bottom: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width: 768px) {
        position: fixed;
        top: 25px;
        left: 25px;
        z-index: 100;
      }
      img {
        width: 20px;
      }
    }
    .links-container {
      @media (max-width: 768px) {
        margin-top: 50px;
      }
    }
    .icon-container {
      padding: 15px 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 65px;
      text-align: center;
      transition: 0.2s ease-in-out;
      cursor: pointer;
      position: relative;
      .icon {
        font-size: 25px;
        color: $colorWhite;
      }
      h2 {
        display: none;
        position: absolute;
        z-index: 2;
        top: 0px;
        left: 0px;
        font-size: 16px;
        font-weight: 500;
        background-color: $colorPrimaryDark;
        color: $colorWhite;
        height: 100%;
        text-align: center;
        justify-content: center;
        align-items: center;
        padding: 0px 20px;
        border-radius: 0px 4px 4px 0px;
        font-family: $fontFamilySecondary;
        transform: translate(0, 0);
        transition: 0.2s ease;
      }

      &:hover {
        background-color: $colorPrimaryDark;
        .icon {
          color: $colorSecondaryLight;
        }
        h2 {
          display: flex;
          transform: translate(60px, 0);
        }
      }

      &.active {
        background-color: $colorPrimaryDark;
        .icon {
          color: $colorSecondaryLight;
        }
      }
    }
  }
  .logout-container {
    margin-top: auto;
    margin-bottom: 50px;
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    text-align: center;
    transition: 0.2s ease-in-out;
    background-color: $colorPrimaryLight;
    cursor: pointer;
    .icon {
      color: $colorWhite;
      transform: rotate(180deg);
    }
    &:hover {
      background-color: $colorPrimaryDark;
      .icon {
        color: $colorSecondaryLight;
      }
    }
  }
}
