.login {
  position: relative;
  height: 100vh;
  text-align: center;
  background-color: var(--primary-color);

  .aura-logo {
    width: 17%;
    margin: auto;
    margin-bottom: 40px;
  }
  .aura-logo img {
    width: 100%;
  }
}
.login .right-top-vendor {
  position: absolute;
  top: 0;
  right: 0;
  height: auto;
  width: 10%;
}
.login .right-top-vendor img {
  height: auto;
  width: 100%;
}
.login .left-bottom-vendor {
  height: auto;
  width: 10.3%;
  position: absolute;
  bottom: 0;
  left: 0;
}
.login .left-bottom-vendor img {
  transform: rotateX(180deg);
  height: auto;
  width: 100%;
}
.login .form-control {
  background-color: var(--secondary-color-dk1);
  border: 0;
}
.form-control::placeholder {
  color: var(--primary-color-dk);
  font-family: AnyaTamy;
  opacity: 50%;
  font-size: 12px;
  padding: 2px;
}
.login .btn {
  background-color: var(--secondary-color-dk2);
  border-radius: 4px;
  color: var(--secondary-color);
  font-family: AffiliateMastery;
  font-size: 12px;
}

@media only screen and (max-width: 768px) {
  .login .right-top-vendor {
    width: 20%;
  }

  .login .left-bottom-vendor {
    width: 20%;
  }
  .aura-logo {
    width: 35%;
    margin: auto;
    margin-bottom: 40px;
  }
  .login .col-md-2 {
    width: 50%;
  }
}
@media only screen and (min-width: 1500px) and (max-width: 1540px) {
  .login .right-top-vendor {
    width: 7%;
  }

  .login .left-bottom-vendor {
    height: auto;
    width: 7.4%;
  }
}

@media only screen and (min-width: 1541px) {
  .login .right-top-vendor {
    width: 7%;
  }

  .login .left-bottom-vendor {
    height: auto;
    width: 7.4%;
  }
  .login .left-bottom-vendor img {
    transform: rotateX(180deg);
    height: auto;
    width: 100%;
  }
}
