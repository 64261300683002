:root {
  --primary-color: #6d7254;
  --primary-color-dk: #3f4631;
  --secondary-color: #efe6dc;
  --secondary-color-dk1: #ddc7ba;
  --secondary-color-dk2: #aa7258;

  --main-color: #6d7254;
  --font-color: #efe6dc;
  --input-color: #ddc7ba;
  --input-text-color: #3f4631;
  --button-color: #aa7258;
}

@font-face {
  font-family: AffiliateMastery;
  src: url("../src/assets/fonts/affiliate-mastery-demo-regular.ttf");
}
@font-face {
  font-family: AnyaTamy;
  src: url("../src/assets/fonts/AnyaTamyRegular-z8Axa.ttf");
}

@font-face {
  font-family: LilyScriptOne;
  src: url("../src/assets/fonts/LilyScriptOne-Regular.ttf");
}
@font-face {
  font-family: AkayaKanadaka;
  src: url("../src/assets/fonts/AkayaKanadaka-Regular.ttf");
}
@font-face {
  font-family: Aclonica;
  src: url("../src/assets/fonts/Aclonica.ttf");
}
@font-face {
  font-family: AoboshiOne;
  src: url("../src/assets/fonts/AoboshiOne-Regular.ttf");
}
body {
  margin: 0;
  font-family:
    Cairo,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
a {
  color: transparent;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-calendars/styles/material.css";
