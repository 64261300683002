@import "src/globals";

.register-new-sale-modal {
  .customer-data {
    font-family: AoboshiOne;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .customer-data-item {
      width: 50%;
      display: flex;
      align-items: center;
      color: #6d7254;
      font-size: 14px;
      margin-bottom: 10px;
      &:nth-child(even) {
        justify-content: flex-end;
      }
      span {
        font-size: 12px;
        &:nth-child(2) {
          color: $colorPrimaryDark;
          margin-left: 5px;
          font-size: 14px;
        }
      }
    }
  }
  .line-separator {
    width: 100%;
    height: 0.1px;
    background-color: $colorSecondaryDark;
    margin: auto;
    margin-bottom: 20px;
    margin-top: 10px;
  }
  h5 {
    color: $colorPrimaryLight;
    font-family: $fontFamilySecondary;
    font-size: 16px;
    text-align: center;
  }
  .payment-methods {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 15px auto;
    gap: 20px;
    span {
      background-color: $colorSecondaryLight;
      color: gray;
      padding: 2px 22px;
      border-radius: 20px;
      font-family: $fontFamilyPrimary;
      font-weight: 600;
      text-transform: capitalize;
      cursor: pointer;
      &.selected {
        background-color: $colorPrimaryLight;
        color: white;
      }
    }
  }
}
