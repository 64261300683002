@import "src/globals";

.gallery-header-content {
  display: flex;
  margin-left: auto;
  h3 {
    color: $colorSecondaryDarker;
    font-size: 12px;
    margin-right: 20px;
    font-family: $fontFamilyPrimary;
  }
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    gap: 0px;
    align-items: flex-end;
    h3 {
      margin-right: 0px;
    }
  }
}

.gallery-page {
  .line-separator {
    width: 100%;
    height: 1px;
    border-radius: 10px;
    background-color: $colorSecondaryDark;
    margin: 20px 0px;
  }
  .products-sales-body {
    display: flex;
    gap: 40px;
    @media (max-width: 768px) {
      flex-direction: column;
      gap: 40px;
    }
    .section-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // text-transform: uppercase;
      font-size: 16px;
      font-family: $fontFamilyPrimary;
      margin-bottom: 10px;
      .create-btn {
        padding: 0px 15px;
        font-size: 14px;
        height: 35px;
        svg {
          margin-left: 6px;
        }
      }
    }
    .products-container {
      flex: 2;

      .table-container {
        flex: 1;
        .tooltip-container {
          display: flex;
          .item {
            font-size: 10px;
            background-color: $colorPrimaryLight;
            color: $colorWhite;
            padding: 5px 10px;
            border-radius: 20px;
            margin-inline-end: 3px;
          }
        }
      }
    }
    .sales-container {
      flex: 1;
      .sales-items {
        & > div {
          margin-bottom: 16px;
        }
      }
    }
  }
}
