@import "src/globals";

.action-button {
  border-radius: 4px;
  border: 0;
  color: $colorWhite;
  transition: 0.3s ease;
  min-width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 40px;
  cursor: pointer;
  font-family: $fontFamilyPrimary;
}

.colorPrimaryLight-bg {
  background-color: $colorPrimaryLight;
  border: 1px solid $colorPrimaryDark;
  &:hover {
    background-color: $colorPrimaryDark;
  }
}
.colorSecondaryDarker-bg {
  background-color: $colorSecondaryDarker;
  border: 1px solid $colorSecondaryDark;
  &:hover {
    background-color: $colorSecondaryDark;
  }
}
.colorPrimaryDark-bg {
  background-color: $colorPrimaryDark;
  border: 1px solid $colorPrimaryLight;
  &:hover {
    background-color: $colorPrimaryLight;
  }
}

.bg-gray {
  background-color: gray;
  &:hover {
    background-color: gray;
  }
}
