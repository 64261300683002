@import "src/globals";

form {
  input {
    width: 100%;
    margin-top: 10px;
    background-color: $colorSecondaryLight;
    border-radius: 6px;
    border: 0;
    padding: 8px 10px;
    outline: 0;
  }
}
.screnshoot-container {
  background-color: $colorSecondaryLight;
  width: 50%;
  margin: 30px auto 20px;
  padding: 5px;
  border-radius: 6px;
}
.paymentDetailsModal {
  span {
    background-color: $colorSecondaryDark;
    cursor: pointer;
    padding: 1px;
    border-radius: 2px;
    color: black;
  }
}
