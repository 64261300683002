@import "src/globals";

.table-container {
  border-radius: 6px;
  overflow: scroll;
  font-family: $fontFamilyPrimary;
  width: 100%;
  &:has(.table-top-taps) {
    border-top-left-radius: 0px;
    .table-wrapper {
      border-top-left-radius: 0px;
    }
  }
  .table-top-taps {
    background-color: $colorPrimaryLight;
    width: max-content;
    border-radius: 6px 6px 0px 0px;
    overflow: hidden;
    .top-tab {
      width: 120px;
      height: 35px;
      color: $colorWhite;
      position: relative;
      background-color: $colorPrimaryLight;
      outline: none;
      border: none;
      font-weight: 500;
      font-size: 12px;
      font-family: $fontFamilyPrimary;
      &.active {
        background-color: $colorPrimaryDark;
      }
    }
  }

  .table-wrapper {
    border-radius: 6px;
    overflow: hidden;
    table {
      border-collapse: collapse;
      border: 1px solid $colorSecondaryDark;
      min-width: 100%;
      width: max-content;
      text-transform: capitalize;
      overflow-x: scroll;
    }
    tr {
      &td + :not(:first-child) {
        border-bottom: 1px solid $colorSecondaryDark;
      }
    }
    td {
      text-align: left;
      color: $colorPrimaryDark;
      padding: 5px 0px;
      padding-right: 20px;
      position: relative;
      &:nth-child(1) {
        padding-left: 20px;
      }
      @media (max-width: 768px) {
        padding: 2px 15px;
        font-size: 10px;
      }
    }
    th {
      text-align: left;
      background-color: $colorSecondaryDark;
      font-size: 16px;
      color: $colorPrimaryDark;
      font-family: $fontFamilyPrimary;
      padding: 10px;
      padding-right: 30px;
      padding-left: 0px;
      &:nth-child(1) {
        padding-left: 20px;
      }
      @media (max-width: 768px) {
        padding: 10px 15px;
        font-size: 12px;
      }
    }
    .actions-cell {
      display: flex;
      padding-bottom: 8px;
    }

    .loading-container {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px 0px;
      font-size: 12px;
      color: $colorPrimaryLight;
      border: 2px solid $colorSecondaryDark;
      border-radius: 0px 0px 4px 6px;
      width: 100%;
    }
  }
}
