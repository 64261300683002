@import "src/globals";

.editable-cell {
  cursor: pointer;
  position: relative;
  .edit-btn-hover {
    background-color: $colorPrimaryLight;
    color: $colorWhite;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    border-radius: 50px;
    position: absolute;
    top: 8px;
    left: -15px;
    cursor: pointer;
    opacity: 0.7;
    display: none;
    font-size: 14px;
    @media (max-width: 768px) {
      top: 0px;
      left: -2px;
      width: 20px;
      height: 20px;
      font-size: 10px;
    }
  }
  &:hover {
    .edit-btn-hover {
      display: flex;
    }
  }
}
