@import "../../../../globals";
.kitchen-container {
  display: flex;
  gap: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 40px;
  }

  .side-kitchen {
    .new-customer-btn {
      padding: 30px 20px;
      border-radius: 4px;
      border: 1px solid rgba(63, 70, 49, 0.25);
      background: $colorPrimaryLight;
      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
      color: $colorWhite;
      font-size: 18px;
      font-weight: 500;
      font-family: $fontFamilyPrimary;
      transition: 0.2s ease;
      cursor: pointer;
      &:hover {
        background-color: $colorPrimaryDark;
      }
      @media (max-width: 768px) {
        padding: 10px 20px;
        font-size: 14px;
        flex: 1;
        width: 100%;
      }
    }
  }
  .table-container {
    flex: 1;
    .tooltip-container {
      display: flex;
      .item {
        font-size: 10px;
        background-color: $colorPrimaryLight;
        color: $colorWhite;
        padding: 5px 10px;
        border-radius: 20px;
      }
    }
  }
}
