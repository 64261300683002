@import "src/globals";

.events-page {
  .events-container {
    display: flex;
    gap: 20px;
    flex-direction: column;
    .side-events {
      .new-event-btn {
        padding: 30px 20px;
        border-radius: 4px;
        border: 1px solid rgba(63, 70, 49, 0.25);
        background: $colorPrimaryLight;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
        color: $colorWhite;
        font-size: 18px;
        font-weight: 500;
        font-family: $fontFamilyPrimary;
        transition: 0.2s ease;
        white-space: nowrap;
        cursor: pointer;
        &:hover {
          background-color: $colorPrimaryDark;
        }
        @media (max-width: 768px) {
          padding: 12px 10px;
          font-size: 14px;
          width: 100%;
        }
      }
    }
    .events-list {
      flex: 1;
    }
  }
}
