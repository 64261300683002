@import "src/globals";

.space-pass-payment-modal {
  .header-content {
    text-align: center;
  }

  .friends-dropdown {
    background-color: "#DDC7BA";
    border: 0 solid #e6d8c2;
    padding: 4px;
    border-radius: 5px;
    width: 50px;
    outline: none;
    appearance: auto;
    margin-right: 10px;
  }

  .customer-data {
    font-family: AoboshiOne;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .customer-data-item {
      width: 50%;
      display: flex;
      align-items: center;
      color: #6d7254;
      font-size: 14px;
      margin-bottom: 10px;
      &:nth-child(even) {
        justify-content: flex-end;
      }
      span {
        font-size: 12px;
        &:nth-child(2) {
          color: $colorPrimaryDark;
          margin-left: 5px;
          font-size: 14px;
        }
      }
    }
  }
  .submit-btn {
    margin: 30px auto 10px;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: 16px;
    font-family: AoboshiOne;
  }
}
