@import "../../../../globals";
.expenses-container {
  display: flex;
  gap: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 40px;
  }

  .side-expenses {
    .new-customer-btn {
      padding: 30px 20px;
      border-radius: 4px;
      border: 1px solid rgba(63, 70, 49, 0.25);
      background: $colorPrimaryLight;
      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
      color: $colorWhite;
      font-size: 18px;
      font-weight: 500;
      font-family: $fontFamilyPrimary;
      transition: 0.2s ease;
      cursor: pointer;
      &:hover {
        background-color: $colorPrimaryDark;
      }
      @media (max-width: 768px) {
        padding: 10px 20px;
        font-size: 14px;
        width: 100%;
      }
    }
  }
  .table-container {
    flex: 1;
    .tooltip-container {
      display: flex;
      .item {
        font-size: 10px;
        background-color: $colorPrimaryLight;
        color: $colorWhite;
        padding: 5px 10px;
        border-radius: 20px;
      }
    }
    .amount-cell {
      background-color: $colorSecondaryDarker;
      color: white;
      padding: 4px 40px;
      border-radius: 50px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      max-width: 100px;
      font-size: 14px;
      span {
        font-size: 8px;
        margin-bottom: 3px;
        margin-left: 2px;
      }
    }
  }
}
