
.menu {
  background-color: var(--primary-color);
  color: white;
  height: 100vh;
}
.menu h2 {
  font-family: LilyScriptOne;
  font-size: 30px;
}
.menu h5 {
  font-family: AkayaKanadaka;
  font-size: 20px;
}
.right-vector-with-aura {
  position: absolute;
  right: 0;
  top: 0;
  width: 12%;
}
.right-vector-with-aura img {
  width: 100%;
}
.left-vector-with-aura {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 12%;
}
.left-vector-with-aura img {
  width: 100%;
}
.menu-pharse {
  width: 33%;
  margin: auto;
}
.menu-pharse img {
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .menu {
    height: auto;
  }
  .right-vector-with-aura {
    width: 25%;
  }
  .left-vector-with-aura {
    width: 25%;
  }
  .menu-pharse {
    width: 70%;
  }
}
