// Colors
$colorPrimaryLight: #6d7254;
$colorPrimaryDark: #3f4631;
$colorSecondaryLight: #efe6dc;
$colorSecondaryDark: #ddc7ba;
$colorSecondaryDarker: #aa7258;
$colorWhite: #ffffff;

// Fonts
$fontFamilyPrimary: Cairo;
$fontFamilySecondary: 'AoboshiOne';

// Grid Design System
@for $i from 1 through 13 {
  .col--#{$i} {
    width: 8.33% * $i;
  }
}

@for $i from 1 through 100 {
  .m--#{$i} {
    margin: 1px * $i;
  }
  .m-l-#{$i} {
    margin-left: 1px * $i;
  }
  .m-t-#{$i} {
    margin-top: 1px * $i;
  }
  .m-b-#{$i} {
    margin-bottom: 1px * $i;
  }
  .m-r-#{$i} {
    margin-right: 1px * $i;
  }
  .m-v-#{$i} {
    margin-top: 1px * $i;
    margin-bottom: 1px * $i;
  }
  .m-h-#{$i} {
    margin-top: 1px * $i;
    margin-bottom: 1px * $i;
  }
}
@for $i from 1 through 100 {
  .p--#{$i} {
    padding: 1px * $i;
  }
  .p-l-#{$i} {
    padding-left: 1px * $i;
  }
  .p-t-#{$i} {
    padding-top: 1px * $i;
  }
  .p-b-#{$i} {
    padding-bottom: 1px * $i;
  }
  .p-r-#{$i} {
    padding-right: 1px * $i;
  }
  .p-v-#{$i} {
    padding-top: 1px * $i;
    padding-bottom: 1px * $i;
  }
  .p-h-#{$i} {
    padding-top: 1px * $i;
    padding-bottom: 1px * $i;
  }
}

@mixin tag-cell {
  color: white;
  padding: 2px 14px;
  border-radius: 50px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  font-size: 14px;
  background-color: $colorSecondaryDark;
}
