@import "src/globals";

.event-form {
  position: relative;
  height: auto;
  min-height: 100vh;
  text-align: center;
  padding: 50px;
  background-color: $colorPrimaryLight;
  .registration-form {
    text-align: center;

    h1 {
      color: $colorWhite;
      text-align: center;
      font-family: AffiliateMastery;
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    p {
      margin-bottom: 70px;
      color: #ddc7ba;
      text-align: center;
      font-family: AoboshiOne;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .form-container {
      margin: auto;
      background-color: $colorSecondaryLight;
      width: 35%;
      border-radius: 10px;
      padding: 20px;

      .note {
        background-color: $colorPrimaryLight;
        color: $colorWhite;
        font-family: AoboshiOne;
        width: fit-content;
        margin: auto;
        padding: 10px 20px;
        border-radius: 4px;
        margin-bottom: 25px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        h4 {
          font-size: 16px;
        }
        h5 {
          font-size: 12px;
        }
      }

      .success {
        .check-icon {
          color: $colorSecondaryLight;
          font-size: 40px;
          width: 65px;
          height: 65px;
          border-radius: 50%;
          background-color: $colorPrimaryLight;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: auto;
        }
        h2 {
          color: #6d7254;
          text-align: center;
          font-family: AffiliateMastery;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-top: 15px;
        }
        p {
          color: #aa7258;
          text-align: center;
          font-family: AoboshiOne;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
      h3 {
        color: $colorPrimaryDark;
        text-align: center;
        font-family: AoboshiOne;
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      p {
        color: $colorPrimaryLight;
        text-align: center;
        font-family: AoboshiOne;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 25px;
      }
      form {
        width: 100%;
        input, select {
          margin: auto;
          display: block;
          width: 90%;
          height: 40px;
          flex-shrink: 0;
          border-radius: 4px;
          background: #ddc7ba;
          border: 0;
          outline: 0;
          padding: 18px;
        }
        select {
          padding-top: 0;
          padding-bottom: 0;
        }

        .error {
          color: #ff3333;
          font-family: AoboshiOne;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
  .left-bottom-corner {
    position: absolute;
    left: 0;
    bottom: 0;
    text-align: left;
    img {
      transform: rotateX(180deg);
      width: 45%;
    }
  }
  .right-bottom-corner {
    position: absolute;
    right: 0;
    bottom: 0;
    text-align: end;
    img {
      width: 55%;
    }
  }
}

@media only screen and (max-width: 600px) {
  .event-form {
    position: relative;
    height: 100vh;
    text-align: center;
    padding: 50px;
    background-color: $colorPrimaryLight;
    .registration-form {
      text-align: center;

      h1 {
        color: $colorWhite;
        text-align: center;
        font-family: AffiliateMastery;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      p {
        margin-bottom: 40px;
        color: $colorSecondaryDark;

        font-size: 8px;
      }
      .form-container {
        width: 100%;

        .success {
          .check-icon {
            font-size: 30px;
            width: 55px;
            height: 55px;
          }
          h2 {
            color: #6d7254;
            text-align: center;
            font-family: AffiliateMastery;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-top: 15px;
          }
          p {
            color: #aa7258;
            text-align: center;
            font-family: AoboshiOne;
            font-size: 8px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }

        h3 {
          font-size: 20px;
        }
        p {
          font-size: 8px;
          margin-bottom: 20px;
        }
        form {
          input {
            width: 100%;
          }
        }
      }
    }
    .left-bottom-corner {
      position: absolute;
      left: 0;
      bottom: 0;
      text-align: left;
      img {
        transform: rotateX(180deg);
        width: 30%;
      }
    }
    .right-bottom-corner {
      position: absolute;
      right: 0;
      bottom: 0;
      text-align: end;
      img {
        width: 35%;
      }
    }
  }
}
