@import "src/globals";

.statistics-page {
  .date-section {
    & > div {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      & > div {
        flex: 1 1 calc(25% - 15px); // 4 items per row with 20px gap
        min-width: 200px; // Minimum width before wrapping
        
        @media (max-width: 1200px) {
          flex: 1 1 calc(50% - 10px); // 2 items per row
        }
        
        @media (max-width: 600px) {
          flex: 1 1 100%; // 1 item per row
        }
      }
    }
    h3 {
      background-color: $colorSecondaryDark;
      border-radius: 50px;
      font-size: 14px;
      width: 100px;
      height: 30px;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 15px;
      font-family: $fontFamilyPrimary;
    }
    .line-separator {
      display: block;
      width: 100%;
      height: 1px;
      border-radius: 10px;
      background-color: $colorSecondaryDark;
      margin: 15px 0px 20px;
    }
  }
}
